import PropTypes from 'prop-types';
import React     from 'react';

import { Legal } from '../../../common/legal';
import { Price } from '../../../common/product/price';
import { RemainingTime } from '../../../common/product/remaining-time';
import { Expiry } from '../../../common/product/expiry';
import { SimpActive }            from './simp-active';
import { SimpCancelled }         from './simp-cancelled';
import { SimpMobile }            from './simp-mobile';
// import { CancelledBox }          from '../../../common/product/cancelled-box';
import { ProductName }           from '../../../common/product/prod-name';
import { TagLine }               from '../../../common/product/tag-line';
import { useGlobalCtx }          from '../../../../context/ctx-hook';
import * as ProductSelectors     from '../../../../context/selectors/product-selectors';
import { getUserSubHistory }     from '../../../../context/selectors/user-selectors';
import { daysLeft, isInBetween } from '../../../../helpers/time-helpers';
import { getSimpPriceWarning } from '../../../../helpers/skunotices-helpers';
import * as RouteHelpers from '../../../../helpers/route-helper';

import { setCtxNestedObj } from '../../../../context/ctx-actions';
// import { useGlobalCtx } from '../../../../context/ctx-hook';
// import { setCtxNestedObj } from '../../../../context/ctx-actions';
// import { useGlobalCtx } from '../../../../context/ctx-hook';
import { getProductsPaymentMethod } from '../../../../context/selectors/user-selectors';
import { useAutoRenew } from '../../../../helpers/hooks/use-auto-renew';
import * as StateConstants from '../../../../helpers/constants/state-constants';
// import { getSimpPriceWarning } from '../../../../helpers/skunotices-helpers';
import { CancelDowngradeBox } from '../../../modals/change-plans/cancel-downgrade';


import * as S from './simp-tile.module.scss';

const PRODUCT = 'acme';
// const annualSku = '170522';


const getAccountMsg = ({ active, autoRenew }, hasPayment) => {
  if (!active) {
    return {
      title: 'Expired Subscription',
      message: 'This subscription is no longer active',
    };
  } else if (!hasPayment) {
    return {
      title: 'No Payment Method',
      message: 'In order to not lose your connected services, add a payment method for renewal'
    };
  } else if (!autoRenew) {
    return {
      title: 'Renewal Canceled',
      message: 'Your subscription has been canceled but it is still valid until your expiration date. In order to avoid expiration, you must resume your subscription.'
    };
  } else {
    return false;
  }
};

const SimpTile = ({isModal, product }) => {
  const { state, globalDispatch }      = useGlobalCtx();
  const SUB          = ProductSelectors.getSubscription(state, product);
  const HISTORY_LIST = getUserSubHistory(state, product);
  const IS_MOBILE    = product !== 'acme';
  const { sku, renewalSku, renewalFrequency, expireOn, active, autoRenew, isInTrialPeriod, isCancelled } = SUB;
  const handleArOn    = useAutoRenew(PRODUCT, true);
  const HAS_PAYMENT   = getProductsPaymentMethod(PRODUCT, state);
  const MESSAGE = getAccountMsg({ active, autoRenew }, getProductsPaymentMethod(PRODUCT, state));
  const PENDING_DOWNGRADE = SUB?.renewalTierUriName ? SUB?.renewalTierUriName !== SUB?.tierUriName : false;

  
  const manageSubscription = React.useCallback(
    async () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.SUBSCRIPTION,
      ctx:        {product: [SUB]},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );

  const updatePaymentModal = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.UPDATE_PAYMENT,
      ctx:        { product: PRODUCT, paymentOnly: false, isDefaultPayment: true },
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );
  
  const IS_CANCELLED = React.useMemo(
    () =>
      !SUB.active || (!SUB.autoRenew && HISTORY_LIST?.filter(({action, createdAt}) =>
        (action === 'DISABLE_AUTORENEW' && isInBetween(SUB.effectiveAt, SUB.expireOn, createdAt))
      ).length > 0),
    [SUB.autoRenew, SUB.effectiveAt, SUB.expireOn, HISTORY_LIST]
  );

  const DAYS_LEFT = React.useMemo(
    () => daysLeft(SUB.expireOn),
    [SUB.expireOn]
  );

  const { HAS_PRICE_WARNING, NEW_PRICE } = getSimpPriceWarning(sku, expireOn, state.featureFlags);
  
  const HAS_ADDONS = false;

  return (
    <div className={`${S.simpTile} acme`}>
      {/* {IS_CANCELLED && <CancelledBox className={S.cancelTrialBox}/>} */}
      {/* <ProductName sub={SUB} name={SUB.tierName} className={S.acmeName}/> */}
      <div className={S.subTileTop}>
        <div className={S.subTileLeft}>
          <ProductName sub={SUB} product='simplifi' className={S.acmeName} />
          <TagLine uriName={SUB.tierUriName} className={S.tagLine} />
          
          {!MESSAGE && 
            <Price
              sku={renewalSku}
              renewalFrequency={renewalFrequency}
              className={`${S.price} ${(HAS_ADDONS || PENDING_DOWNGRADE) ? S.hasAddons : false}`}
              price={HAS_PRICE_WARNING ? NEW_PRICE : false}
            />
          }
        </div>

        <div className={S.subTileRight}>
          <Expiry
            product="acme"
            className={`${S.productExpiry}`}
          />

          {isInTrialPeriod && (
            <>
              <h4 className={S.trialHeading}>Free trial</h4>
              <RemainingTime expireOn={expireOn} className={S.trialRemaining} />
            </>
          )}

          {/* <Price
            sku={renewalSku}
            renewalFrequency={renewalFrequency}
            className={S.price}
            price={HAS_PRICE_WARNING ? NEW_PRICE : false}
          /> */}

          {!isInTrialPeriod && active && (!autoRenew || !HAS_PAYMENT) && (
            <RemainingTime
              expireOn={expireOn}
              isCancelled={isCancelled}
              className={S.remaining}
            />
          )}
  
          {/* <span className={`${S.dash}`} /> */}
  
          {!isModal && active && autoRenew && HAS_PAYMENT && (
            <button
              type="button"
              onClick={manageSubscription}
              className={S.ctaBtn}
              data-testid="BTN_ACME_MANAGE_SUB"
            >Manage Subscription</button>
          )}
  
          {active && autoRenew && !HAS_PAYMENT && (
            <button
              type="button"
              onClick={updatePaymentModal}
              className={S.ctaBtn}
              data-testid="BTN_ACME_ADD_BILLING"
            >Add Billing</button>
          )}
  
          {active && !autoRenew && (
            <>
              <button
                type="button"
                onClick={HAS_PAYMENT ? handleArOn : updatePaymentModal}
                className={S.ctaBtn}
                data-testid="BTN_ACME_RESTART_SUB"
              >Resume Subscription</button>
              <Legal className={S.legal}/>
            </>
          )}

          {!active && (
            <a
              data-testid="LINK_RENEW_QKN"
              className={S.ctaBtn}
              // href={RouteHelpers.getEStoreRenewal(state.country)}
              href={RouteHelpers.getEStoreCartUrlAddProduct(renewalSku, state.country)}
            >Buy Now</a>
          )}
  
          {/* <img
            src={IS_HAPPY_STAR ? HAPPY_STAR : MEH_STAR}
            alt={IS_HAPPY_STAR ? 'Happy Star' : 'Sad Star'}
            className={S.star}
          /> */}
        </div>


        {/* {IS_MOBILE
          ? <SimpMobile product={product} {...SUB} />
          : DAYS_LEFT > 0
            ? <SimpActive {...SUB} isCancelled={IS_CANCELLED} isModal={isModal}/>
            : <SimpCancelled {...SUB} />
        } */}
      </div>

      {/* {!isModal && MESSAGE && 
        <div className={S.productAlert}>
          <p className={S.messageTitle}>{MESSAGE.title}</p>
          <p>{MESSAGE.message}</p>
        </div>
      } */}

      {PENDING_DOWNGRADE && SUB.autoRenew &&
        <CancelDowngradeBox product={PRODUCT} className={'mainTile'} />
      }

      {!isModal && MESSAGE && 
        <div className={S.productAlert}>
          <p className={S.messageTitle}>{MESSAGE.title}</p>
          <p>{MESSAGE.message}</p>
        </div>
      }

      {/* {!isModal && MESSAGE && <div className={S.productAlert}>{MESSAGE}</div>} */}


      {/* {HAS_ADDONS &&
        <div className={S.subTileBtm}>
          <span className={S.dash} />
            do addon stuff
        </div>
      } */}
    </div>
  );
};

SimpTile.displayName  = 'SimpTile';
SimpTile.propTypes    = {
  isModal: PropTypes.bool,
  product: PropTypes.string.isRequired
};
SimpTile.defaultProps = {isModal: false};

export { SimpTile };
