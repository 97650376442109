import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as MixPanel from '../../../helpers/mixpanel';
import * as S from './confirm-downgrade.module.scss';

import { ModalWrapper } from '../modal-wrapper';
import { TagLine } from '../../common/product/tag-line';
import { Expiry } from '../../common/product/expiry';
import { Price } from '../../common/product/price';
import { ProductName } from '../../common/product/prod-name';

import { useGlobalCtx } from '../../../context/ctx-hook';
import * as ProductSelectors from '../../../context/selectors/product-selectors';
import * as Actions from '../../../context/ctx-actions';

import * as NetworkCalls from '../../../network/network-calls';
import { filterSimpSubs, createSubscriptionMap } from '../../../helpers/pure-functions';

import RED_X_ICON from '../../../assets/imgs/icons/red_x_icon.svg';


const ConfirmDowngrade = ({ onClose, ctx: { product, currentTierUri, desiredTierUri, sku } }) => {
  const {globalDispatch, state} = useGlobalCtx();
  
  const currentTier = currentTierUri;
  const SUB = ProductSelectors.getSubscription(state, product);
  const isQkn = ['quicken-us', 'quicken-ca'].includes(product);

  const desiredTier = desiredTierUri;
  const desiredSkuData = state.skus[sku];
  const desiredSkuHardcodedData = ProductSelectors.getChangePlanProductOfferings(product).filter(productInfo => productInfo.sku === sku)[0] || false;

  const desiredIsQkn = ['quicken-us', 'quicken-ca'].includes(product);
  const desiredProduct = desiredIsQkn ? 'quicken' : 'simplifi';

  const pointsTitle = isQkn ? 'Don\'t forget...' : 'Did you know that ... ';
  const downgradePoints = ProductSelectors.getDowngradeProductPoints(currentTierUri);

  const handleDowngrade = async () => {
    try {
      globalDispatch(Actions.mergeMainStateObj({modal: {isDisabled: true }}));
      
      const { data } = await NetworkCalls.callPostDowngrade(product, desiredTier);
      const subResponseAgain = await NetworkCalls.callGetSubscriptions();
      const SUB_MAP = filterSimpSubs(createSubscriptionMap(subResponseAgain.data, state.country));

      globalDispatch(Actions.mergeMainStateObj({
        isDisabled: false,
        subscriptions: SUB_MAP,
        // alert: { type: 'SUCCESS', messages: ['Your membership is now set up']},
      }));
      onClose();
    } catch (err) {
      console.log(err);
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.DOWNGRADE);
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  };

  // console.log('product: ' + product);
  // console.log('currentTier: ' + currentTier);
  // console.log('desiredTier: ' + desiredTier);
  // console.log('pointsTitle: ' + pointsTitle);
  // console.log('desiredSkuData: ' + desiredSkuData);
  // console.log(desiredSkuData);
  // console.log('downgradePoints: ' + downgradePoints);
  // console.log('SUB: ' + SUB);
  // console.log(SUB);
  // console.log('isQkn: ' + isQkn);

  return (
    <ModalWrapper heading="Downgrade Plan" onClose={onClose}>
      <div className={S.modalBody}>
        <div className={S.pointsContain}>
          <h4 className={S.pointsTitle}>{pointsTitle}</h4>
          {isQkn &&
            <div className={S.pointsSubline}>
              <span>After downgrading, you&apos;ll lose full access to </span>
              <ProductName className={S.downgradeProdName} sub={SUB} nameStyle={'textOnly'} />
              <span> features, including:</span>
            </div>
          }

          <div className={S.points}>
            {downgradePoints.map((point, i) =>
              <div className={S.pointWrap} key={i}>
                <img className={S.bulletImg} alt='red x bullet point icon' src={RED_X_ICON}></img>
                <span>{point}</span>
              </div>
            )}
          </div>
          {currentTier === 'premier' &&
            <p className={S.windOnly}>*Windows only</p>
          }
        </div>

        <div className={classNames(S.productCard, isQkn ? S.redCard : S.blurpleCard)}>
          <div className={S.left}>
            <ProductName className={S.downgradeProdName} product={desiredIsQkn ? 'quicken' : 'simplifi'} customName={desiredSkuHardcodedData.name} />

            <TagLine uriName={desiredSkuData.Tier} className={S.tagLine} />

            <Price
              sku={desiredSkuData.SKU}
              renewalFrequency={desiredSkuData.RenewalFrequencyISODuration}
              className={S.priceRow}
            />
          </div>

          <div className={S.right}>
            <Expiry product={product} className={S.infoExpiryRow} isDowngrade={true} />
          </div>
        </div>

        <div className={S.btnRow}>
          <button
            type="button"
            onClick={onClose}
            className={[`${S.contentCTABtn} ${S.keepCurrentTier}`]}
            disabled={false}
          >Keep current plan</button>

          <button
            type="button"
            onClick={handleDowngrade}
            className={S.contentCTABtn}
            disabled={false}
          >Downgrade Plan</button>
        </div>
      </div>
    </ModalWrapper>
  );
};

ConfirmDowngrade.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired,
    currentTierUri: PropTypes.string.isRequired,
    desiredTierUri: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
  })
};

export { ConfirmDowngrade };