import { MODALS }  from './constants/state-constants';
import AMEX_LOGO   from '../assets/imgs/credit/amex-icon.svg';
import DISC_LOGO   from '../assets/imgs/credit/disc-icon.svg';
import MC_LOGO     from '../assets/imgs/credit/mc-icon.svg';
import VISA_LOGO   from '../assets/imgs/credit/visa-icon.svg';
import DASH_LOGO   from '../assets/imgs/credit/card-icon.svg';
import PAYPAL_LOGO from '../assets/imgs/brand/paypal.png';
import * as BM_PROMO from './bmpromo-helpers';


export const getCCLogo = (type = '') => {
// export const getCCLogo = () => {
  // return DASH_LOGO;
  switch (type) {
    case 'american express':
    case 'American Express':
    case 'AE':
      return AMEX_LOGO;
    case 'discover':
    case 'Discover':
    case 'DI':
      return DISC_LOGO;
    case 'mastercard':
    case 'MasterCard':
    case 'MC':
    case 'MA':
      return MC_LOGO;
    case 'visa':
    case 'Visa':
    case 'VI':
      return VISA_LOGO;
    default:
      return DASH_LOGO;
  }
};

export const payPalImg = email => ({
  src:  PAYPAL_LOGO,
  alt:  'PayPal logo',
  text: email ? email : ''
});

export const creditImg = (cc_type, cc_last4) => ({
  src:  getCCLogo(cc_type),
  alt:  `${cc_type} logo`,
  text: cc_last4 ? `ending in x${cc_last4}` : ''
});

export const getFormattedPrice = price => {
  if (!price || isNaN(price)) {
    return null;
  }

  return parseFloat(Math.round(price * 100) / 100).toFixed(2);
};

export const getModalAssist = ({loc, product_line = null, bmp}) => {
  if(bmp) {
    BM_PROMO.setBmPromoValues(bmp.toLowerCase());
  }

  if (loc === 'phone') {
    return {
      type: MODALS.PHONE,
      isLoading: true,
      hasError: false,
      isDisabled: false,
    };
  }
  
  if (loc === 'subscription') {
    return {
      type: MODALS.SUBSCRIPTION,
      // ctx:  {product: product_line},
      isLoading: true,
      hasError: false,
      isDisabled: false,
    };
  }
  
  if (loc === 'change-plan' && product_line) {
    return {
      type: MODALS.CHANGE_PLAN,
      ctx:  {product: product_line},
      isLoading: true,
      hasError: false,
      isDisabled: false,
    };
  } else if (loc === 'update-payment') {
    return {
      type: MODALS.UPDATE_PAYMENT,
      ctx: { product: product_line, paymentOnly: false, isDefaultPayment: true },
      isLoading: true,
      hasError: false,
      isDisabled: false,
    };
  } else {return null;}
};

// export const getPaymentType = (method) => {
//   switch (method) {
//     case 'braintree_ach_direct_debit':
//     case 'US Bank Account':
//       return 'ACH';
//     case 'braintree_paypal':
//     case 'Braintree (PayPal)':
//       return 'PAYPAL';
//     case 'free':
//       return 'FREE';
//     case 'Credit Card':
//       return 'CC';
//     default:
//       return 'CC';
//   }
// };