import React from 'react';

import * as S       from './subscription-view.module.scss';
import { TileQkn }  from './tile-qkn/tile-qkn';
import { TileQem }  from './tile-qem/tile-qem';
import { SimpTile } from './tile-simp/simp-tile';
import { TileCoTerm } from './tile-coterm/coterm-tile';
import { AdvertTile } from './tile-advert/advert-tile';
import { ViewWrapper }       from '../../common/view-wrapper';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import { useOnPageChange }   from '../../../helpers/hooks/use-on-page-change';
import { getBillManagerType } from '../../../context/selectors/bill-selectors';

const SubscriptionView = () => {
  const {state}   = useGlobalCtx();
  const IS_USA    = state.country === 'us';
  
  const hasSpecificProducts = (coTermProductLines) => {
    return coTermProductLines?.length === 2 && coTermProductLines.includes('quicken-online-backup') && coTermProductLines.includes(`quicken-${state.country}`);
  };

  // A ridiculous name for a ridiculous function
  const splitCotermGroupsAndActLikeQuickenAndObAreNotCotermWhenTheyReallyAre = (cotermSubs) => {
    const groups = new Map();
    cotermSubs.forEach(sub => {
      const key = JSON.stringify(sub.coTermProductLines.sort());
      if (!groups.has(key)) {
        groups.set(key, []);
      }
      groups.get(key).push(sub);
    });
    return Array.from(groups.values()).filter(group => !hasSpecificProducts(group[0].coTermProductLines));
  };

  // Coterm tile
  const ALL_COTERM_SUBS = Object.values(state.subscriptions).filter(sub => sub.isCoTerminus);
  let COTERM_SUBS = splitCotermGroupsAndActLikeQuickenAndObAreNotCotermWhenTheyReallyAre(ALL_COTERM_SUBS);

  // Single Tiles
  // this HAS_QKN only checks for non-cotermed quickens
  const HAS_QKN = !!state.subscriptions?.[IS_USA ? 'quicken-us' : 'quicken-ca'] && !state.subscriptions?.[IS_USA ? 'quicken-us' : 'quicken-ca'].isCoTerminus;  
  const HAS_QEM = !!state.subscriptions?.['quicken-estate-manager'] && !state.subscriptions?.['quicken-estate-manager'].isCoTerminus;
  const SIMP_SUBS = Object.values(state.subscriptions).filter(sub =>{
    return sub.productLineUriName == 'acme' && !sub.isCoTerminus;
  });

  // Addon checks for advertisement tile
  const BM_TYPE = getBillManagerType(state);
  // Check if they have any quicken co-term on not
  const HAS_ANY_QKN = !!state.subscriptions?.[IS_USA ? 'quicken-us' : 'quicken-ca'];
  const HAS_QOB = !!state.subscriptions?.['quicken-online-backup'];
  const QKN_SUB_ACTIVE = Object.values(state.subscriptions).filter(sub => sub.productLineUriName === 'quicken-us' && sub.autoRenew === true && sub.active === true).length > 0;
  const SHOW_ADVERT = HAS_ANY_QKN && QKN_SUB_ACTIVE && IS_USA && (BM_TYPE === 'NONE' || !HAS_QOB);
  const PRODUCT_LINE_ADS = [];
  if(BM_TYPE === 'NONE') PRODUCT_LINE_ADS.push('billmanager');
  if (!HAS_QOB) PRODUCT_LINE_ADS.push('quicken-online-backup');

  const COTERM_IS_QKN_AND_QOB = ALL_COTERM_SUBS.filter(sub => ['quicken-us', 'quicken-ca', 'quicken-online-backup'].includes(sub.productLineUriName));
  const ACT_LIKE_COTERM_IS_NOT_COTERM = COTERM_IS_QKN_AND_QOB.length === 2 && COTERM_IS_QKN_AND_QOB.every(sub => hasSpecificProducts(sub.coTermProductLines));
  const QUICKEN_SUB = ACT_LIKE_COTERM_IS_NOT_COTERM ? HAS_ANY_QKN : HAS_QKN;

  useOnPageChange();

  return (
    <ViewWrapper testId="SUBSCRIPTION_AREA" title="Subscriptions">
      <div className={S.boxArea}>
        {COTERM_SUBS.length > 0 && COTERM_SUBS?.map((coTermGrp, i) => 
          <TileCoTerm key={i} subs={coTermGrp} />
        )}

        {QUICKEN_SUB && <TileQkn/>}
        
        {IS_USA && SIMP_SUBS.length > 0 && (
          <div className={S.simpArea}>
            {SIMP_SUBS.map(sub => <SimpTile key={sub} product={sub.productLineUriName}/>)}
          </div>
        )}
        {HAS_QEM && <TileQem/>}

        {SHOW_ADVERT &&
          <AdvertTile 
            productLineAds={PRODUCT_LINE_ADS}
          />
        }
      </div>
    </ViewWrapper>
  );
};

SubscriptionView.displayName = 'SubscriptionView';

export { SubscriptionView };
