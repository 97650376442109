import PropTypes        from 'prop-types';
import React            from 'react';
import * as S           from './change-plan-acme.module.scss';


import { ModalWrapper }       from '../../modal-wrapper';
import { PlanBoxAcme }        from './plan-box-acme';
import { ProductName }        from '../../../common/product/prod-name';
import { TagLine }            from '../../../common/product/tag-line';
import { Price }              from '../../../common/product/price';
import { useGlobalCtx }       from '../../../../context/ctx-hook';
import * as ProductSelectors  from '../../../../context/selectors/product-selectors';
// import * as UserSelectors     from '../../../../context/selectors/user-selectors';
// import * as RouteHelpers      from '../../../../helpers/route-helper';
// import * as TimeHelpers       from '../../../../helpers/time-helpers';
// import { getQknPriceWarn }    from '../../../../helpers/skunotices-helpers';
import { Expiry }             from '../../../common/product/expiry';
import { CancelDowngradeBox } from '../cancel-downgrade';


// const SIMP_PLANS = [
//   { sku: '170538', uriName: 'acme-hab', name: 'Business & Personal' },
//   { sku: '170522', uriName: 'acme-basic', name: 'Simplifi' }
// ];
// const SIMP_PLANS = ProductSelectors.getAcmeProductOfferings();
// const SIMP_PLANS = ProductSelectors.getChangePlanProductOfferings(product, state.country);

const PRORATE_UPG_MSG = 'You will be charged a prorated amount based on the time remaining on your subscription.';
const PRORATE_DWNGRD_MSG = 'Your subscription will automatically renew to this plan at the end of your current term.';
// const 

const ChangePlanAcme = ({onClose, ctx: {product}}) => {
  const {state} = useGlobalCtx();
  const SUB = ProductSelectors.getSubscription(state, product);
  const SIMP_PLANS = ProductSelectors.getChangePlanProductOfferings(product, state.country);
  const PLAN_INDEX = SIMP_PLANS.findIndex(({ uriName }) => uriName === SUB.tierUriName);
  const PENDING_DOWNGRADE = SUB.renewalTierUriName ? SUB.renewalTierUriName !== SUB.tierUriName : false;

  if (!SUB?.active) {
    onClose();
  }

  return (
    <ModalWrapper heading="Change Plan" onClose={onClose} className={S.wrapper}>
      <div className={S.qknCPWrapper} data-testid={'SIMP_CHANGE_PLAN_CURRENT_PLAN'}>
        <div>
          <div className={S.label}>Current Plan</div>
          <article className={`${S.currentPlan} ${SUB.tierUriName}`}>
            <div className={S.currentLeft}>
              <ProductName sub={SUB} className={S.name} product={product} />

              <TagLine uriName={SUB.tierUriName} className={S.tag}/>
              
              <Price
                renewalFrequency={SUB.renewalFrequency}
                sku={SUB.renewalSku}
                className={S.currentPrice}
              />
            </div>

            <div className={S.currentRight}>
              <Expiry product={product} className={S.infoExpiryRow} />
            </div>

            {PENDING_DOWNGRADE &&
              <div className={S.cancelDgradeBox}>
                <CancelDowngradeBox product={product} className={'changePlan'} />
              </div>
            }

          </article>
        </div>

        <div className={S.dash}/>

        <div>
          <div className={S.label}>Other Options</div>
          <div className={S.optionArea} data-testid={'SIMP_CHANGE_PLAN_OPTIONS_BOXES'}>
            {SIMP_PLANS.map(({uriName, sku, name}, i) => {
              if (uriName === SUB.tierUriName) return null;
              return (
                <PlanBoxAcme
                  key={sku}
                  name={name}
                  sku={sku}
                  country={state.country}
                  currentTierUri={SUB.tierUriName}
                  renewalTierUriName={SUB.renewalTierUriName}
                  uriName={uriName}
                  action={PLAN_INDEX > i ? 'UPGRADE' : 'DOWNGRADE'}
                  boxText={PLAN_INDEX > i ? PRORATE_UPG_MSG : PRORATE_DWNGRD_MSG}
                  data-testid={'SIMP_CHANGE_PLAN_PLAN_BOX'}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};


ChangePlanAcme.displayName = 'ChangePlanAcme';
ChangePlanAcme.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired
  })
};

export { ChangePlanAcme };
