import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import * as S from './payment.module.scss';

import { ViewWrapper } from '../../common/view-wrapper';
import { SubHistory } from '../../../components/modals/subscription-modal/sub-payment-history';
import { useGlobalCtx } from '../../../context/ctx-hook';
import { PaymentRow } from './paymentRow';
import { supportedProductLineUriNames } from './../../../helpers/pure-functions';

import { Spinner } from '../../common/spinner/spinner';
import { callGetPaymentHistory } from '../../../network/network-calls';
import { mergeMainStateObj } from '../../../context/ctx-actions';

const unpackProductLineDefaults = (productLinePayMethods) => {
  return productLinePayMethods.flatMap(obj =>
    obj.defaultForProductLines.map(item => ({ [item]: { ...obj } }))
  );
};

const PaymentView = () => {
  const {globalDispatch, state}   = useGlobalCtx();
  const [historyLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getApiData() {
      if (!state.paymentHistoryCalled) {
        const { data } = await callGetPaymentHistory();
        globalDispatch(mergeMainStateObj({
          paymentHistory: data,
          paymentHistoryCalled: true
        }));
      }
      setIsLoading(false);
    }
    getApiData();
  }, []);
  
  const hasNoPayment = [ defaultPayment ]?.length === 0 && productLinePayments?.length === 0;
  const hasPaymentIssue = state?.paymentErr?.length > 0;

  const [ defaultPayment ] = state.payMethods.filter(method => method.defaultMethod === true);
  const productLinePayments = state.payMethods.filter(method => method?.defaultForProductLines?.length > 0 && method.defaultMethod === false);

  // dont even ask about this part, this is literally a filter so I don't have to explain to QA why a few of their users could have an invalid product line in their payment method from a bug on stage during development
  const unpackedProductLines = unpackProductLineDefaults(productLinePayments);
  const productLines = supportedProductLineUriNames();
  const productLineRows = unpackedProductLines.filter(item => {
    return productLines.includes(Object.keys(item)[0]);
  });

  return (
    <ViewWrapper testId="PAYMENT_AREA" title="Payments">
      {hasPaymentIssue &&
        <div className={S.paymentAlert}>
          <p className={S.messageTitle}>Payment method failed</p>
          <p>Your default payment method has failed. Please update your payment details. </p>
        </div>
      }

      {hasNoPayment &&
        <p>No payment methods added yet.</p>
      
      }

      <div className={S.paymentsContain}>
        <div className={S.titleContain}>
          <h3>Saved Cards</h3>
        </div>

        <div className={S.paymentsRowContain}>
          {defaultPayment &&
            <PaymentRow 
              payment={defaultPayment}
              product={'default-payment-only'}
            />
          }

          {productLineRows.map((item, i) => {
            return <PaymentRow 
              key={item[Object.keys(item)[0]].id}
              payment={item[Object.keys(item)[0]]}
              product={Object.keys(item)[0]}
            />;
          })}

          {/* can just do this in the future when an old QA account with an invalid product line isn't being used anymore */}
          {/* {productLinePayments.map(method => {
            return <PaymentRow key={method.id} payment={method} />;
          })} */}
        </div>
      </div>

      <div className={S.history}>
        <h3>Payment History</h3>
        {historyLoading &&
          <div className={S.spinnerContain}>
            <Spinner />
          </div>
        }

        {!historyLoading &&
          <SubHistory product={'all'} historyLoading={historyLoading}/>
        }
      </div>
    </ViewWrapper>
  );
};


PaymentView.displayName = 'PaymentView';

export { PaymentView };
