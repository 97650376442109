import React     from 'react';
import PropTypes from 'prop-types';

import * as S                from './sub-payment-history.module.scss';
import { Pagination }        from '../../common/pagination';
import SM_PAYPAL             from '../../../assets/imgs/brand/paypal-icon.png';
import BANK_ICON             from '../../../assets/imgs/icons/icon-bank2.svg';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as Constants        from '../../../helpers/constants/constants';
import { maskEmail }         from '../../../helpers/masks';
import * as PaymentHelpers   from '../../../helpers/payment-helpers';
import { getShortDate }      from '../../../helpers/time-helpers';
import { ProductName }      from '../../common/product/prod-name';

// God speed working in this componenet, the API it uses is an abomination
function getPaymentType(method) {
  switch (method) {
    case 'braintree_ach_direct_debit':
    case 'braintree.ach':
    case 'US Bank Account':
      return 'ACH';
    case 'braintree_paypal':
    case 'braintree.paypal':
    case 'Braintree (PayPal)':
      return 'PAYPAL';
    case 'free':
      return 'FREE';
    case 'Credit Card':
    case 'braintree.card':
      return 'CC';
    default:
      return 'CC';
  }
}

const getRowDescription = (order, action ) => {
  if (Constants.REFUND_LOOKUP[action]) {
    return `${Constants.REFUND_LOOKUP[action]}` ?? '';
  } else if (Constants.ACTION_TRANSLATIONS[action]) {
    return `${Constants.ACTION_TRANSLATIONS[action]}` ?? '';
  }
  return '';
};

const INTERVAL = 5;

const SubHistory = ({product, historyLoading}) => {
  const {state}                 = useGlobalCtx();
  const [selected, setSelected] = React.useState(0);

  const PAYMENT_HISTORY = state.paymentHistory;
  const LIST_LENGTH = PAYMENT_HISTORY?.length ?? 0;

  if (LIST_LENGTH === 0 && !historyLoading) {
    return <div className={S.noHistory}>No history at this time</div>;
  }

  const START_ROW = (INTERVAL * selected);
  const END_ROW   = (INTERVAL * (1 + selected));

  return (
    <>
      <div className={S.historyWrapper}>
        {PAYMENT_HISTORY.slice(START_ROW, END_ROW).map((order, i) => {
          const PRICE = PaymentHelpers.getFormattedPrice(order?.transactionAmount) ?? '-.--';
          const TYPE = getPaymentType(order?.payment?.method);
          let text;

          if (TYPE === 'PAYPAL') text = order?.customerEmail ? maskEmail(order?.customerEmail) : '';
          else if (TYPE === 'CC') text = order?.payment?.cc_last_4 ? `****${order?.payment?.cc_last_4}` : '';
          else if (TYPE === 'ACH') text = 'Account Details';

          const CREATED_DATE = getShortDate(order?.createdAt);
          const COL_PRICE    = `${Constants.REFUND_LOOKUP[order?.action] ? '-' : ''}$${PRICE}`;
          const DESCRIPTION  = getRowDescription(order, order?.action);

          if (TYPE === 'ACH') {
            return (
              <div key={i} className={S.achRow}>
                <img className={S.bankIcon} role="presentation" src={BANK_ICON} alt="Bank Icon" />
                <span className={S.details}>ACH Direct Debit</span>
                <span className={S.description}>{DESCRIPTION}
                  {order?.lineItems?.map((product, i) =>
                    <span key={i} className={S.inlineProdName}>
                      {i > 0 && <>+</>}
                      <ProductName className={S.inlineProdName} sub={product} customName={product.tier} nameStyle={'textOnly'} />
                      <br />
                    </span>
                  )}
                </span>
                <span className={S.date}>{CREATED_DATE}</span>
                <span className={S.price}>{COL_PRICE}</span>
              </div>
            );
          } else {
            return (
              <div key={i} className={S.paymentRow}>
                <div className={S.img}>
                  {TYPE === 'PAYPAL' && (
                    <img className={S.smPayPal} role="presentation" src={SM_PAYPAL} alt="PayPal Logo"/>
                  )}
                  {TYPE === 'CC' && (
                    <img
                      role="presentation"
                      className={S.card}
                      src={PaymentHelpers.getCCLogo(order?.payment?.cc_type)}
                      alt={`${order?.payment?.cc_type} logo`}
                    />
                  )}
                </div>
                <span className={S.details} title={TYPE === 'PAYPAL' ? text : null}>{text}</span>
                <span className={S.description}>{DESCRIPTION}
                  {order?.lineItems?.map( (product, i) =>
                    <span key={i} className={S.inlineProdName}>
                      {i > 0 && <>+</> }
                      <ProductName className={S.inlineProdName} sub={product} customName={product.tier} nameStyle={'textOnly'}/>
                      <br />
                    </span>
                  )}
                </span>
                <span className={S.date}>{CREATED_DATE}</span>
                <span className={S.price}>{COL_PRICE}</span>
              </div>
            );
          }
        })}
      </div>
      {LIST_LENGTH > INTERVAL && (
        <Pagination
          selected={selected}
          total={LIST_LENGTH}
          interval={INTERVAL}
          onSelection={setSelected}
        />
      )}
    </>
  );
};

SubHistory.displayName = 'SubHistory';
SubHistory.propTypes   = {
  product: PropTypes.string.isRequired,
  historyLoading: PropTypes.bool.isRequired
};

export {SubHistory};
