import client         from 'braintree-web/client';
import hostedFields   from 'braintree-web/hosted-fields';
import paypalCheckout from 'braintree-web/paypal-checkout';
import PropTypes      from 'prop-types';
import React          from 'react';

import { CCForm }                     from './card-form';
import * as PaymentHelpers            from './payment-helpers';
import * as S                         from './payment-modal.module.scss';
import { PaymentSwitcher }            from './payment-switcher';
import { PayPalForm }                 from './paypal-form';
import { ModalBtnRow }                from '../../modal-btn-row';
import { ModalWrapper }               from '../../modal-wrapper';
import { Message }                    from '../../../common/message';
import { CheckboxField }              from '../../../common/inputs/checkbox-field';
import * as Actions                   from '../../../../context/ctx-actions';
import { useGlobalCtx }               from '../../../../context/ctx-hook';
import * as ProductSelectors          from '../../../../context/selectors/product-selectors';
import * as UserSelectors             from '../../../../context/selectors/user-selectors';
import * as MixPanel                  from '../../../../helpers/mixpanel';
import { getEStoreRenewal }           from '../../../../helpers/route-helper';
import { 
  getTealiumObj, 
  gtmLink }                           from '../../../../helpers/tealium';
import * as TimeHelpers               from '../../../../helpers/time-helpers';
import * as Validations               from '../../../../helpers/validations';
import * as NetworkCalls              from '../../../../network/network-calls';
import { callUpdateAddress }          from '../../../../network/quicken-id-calls';
import { AchForm }                    from './ach-form';

function validateZip(country, zip) {
  return country === 'US'
    ? Validations.validateUSPostal(zip)
    : Validations.validateCAPostal(zip);
}

const PaymentModal = ({ onClose, ctx: { product, paymentOnly, acmeAction, sku, isDefaultPayment }}) => {
  const {globalDispatch, state}          = useGlobalCtx();
  const ACH_CTX                          = {product, paymentOnly};
  const [method, setMethod]              = React.useState('CARD');
  const [hasError, setHasError]          = React.useState(false);
  const [_nonce, setNonce]               = React.useState(null);
  const [_hostedFields, setHostedFields] = React.useState(null);
  const [wantsAR, setWantsAR]            = React.useState(false);
  const [isDisabled, setIsDisabled]      = React.useState(true);
  const [hasPPInfo, setHasPPInfo]        = React.useState(false);
  const [qcsRanAr, setQcsRanAr]          = React.useState(false);

  const [ccErrors, setCCErrors] = React.useState({
    cardholderName: '',
    number:         '',
    expirationDate: '',
    cvv:            '',
    postalCode:     ''
  });

  const [paypalForm, setPayPalForm] = React.useState({
    values: {fullName: '', zipCode: '', country: 'US', email: ''},
    errors: {fullName: '', zipCode: '', form: ''}
  });
  
  const DEFAULT_PAY_UPDATE = isDefaultPayment;

  const { active, autoRenew, expireOn, renewalSku, tierName } = product === 'default-payment-only' ? {} : ProductSelectors.getSubscription(state, product);

  const IS_ACME         = product === 'acme';
  const IS_30_AFTER_EXP = TimeHelpers.is30DaysAfter(expireOn);
  const USER_ID         = UserSelectors.getUserId(state);
  // const HAS_PAYMENT     = UserSelectors.getUserHasCredit(state);
  const HAS_PAYMENT     = UserSelectors.getProductsPaymentMethod(product, state);
  const PRICE           = ProductSelectors.getPriceBySku(state, sku);
  const HAS_ADDRESS     = !!state.personalInfo.line1;
  const IS_CANADA       = state.country === 'ca';

  const HAS_EXPIRED_LT_30                      = !active && !IS_30_AFTER_EXP;
  const ACTIVE_QKN_WO_AR_AND_PAYMENT_WITHIN_45 = (active && !IS_ACME && (!autoRenew && !HAS_PAYMENT && TimeHelpers.isWithin45Days(expireOn)));
  const SHOW_RENEW_NOW                         = ACTIVE_QKN_WO_AR_AND_PAYMENT_WITHIN_45 || HAS_EXPIRED_LT_30 && !DEFAULT_PAY_UPDATE;

  const handleValidityChange = ({emittedBy, fields}) => {
    const {isValid, isPotentiallyValid} = fields[emittedBy];

    const MSG = (isValid || isPotentiallyValid)
      ? ''
      : PaymentHelpers.getErrorMsgs(emittedBy);

    setCCErrors((errors => ({...errors, [emittedBy]: MSG})));
  };

  const handleBlur = ({emittedBy, fields}) => {
    fields[emittedBy].container.class            = 'braintree-hosted-fields-invalid';
    const {isEmpty, isValid, isPotentiallyValid} = fields[emittedBy];

    const MSG = (!isEmpty && (isValid || isPotentiallyValid))
      ? ''
      : PaymentHelpers.getErrorMsgs(emittedBy);

    setCCErrors((errors => ({...errors, [emittedBy]: MSG})));
  };

  const handlePayPalInfo = ({nonce, details: {firstName, lastName, billingAddress, email}}) => {
    setNonce(nonce);

    const city    = billingAddress?.city?.trim() ?? '';
    const line1   = billingAddress?.line1?.trim() ?? '';
    const line2   = billingAddress?.line2?.trim() ?? '';
    const zipCode = billingAddress?.postalCode?.trim() ?? '';
    const state   = billingAddress?.state?.trim() ?? '';
    let country   = billingAddress?.countryCode?.trim() ?? 'US';
    country       = country.toUpperCase();

    setPayPalForm(({errors}) => ({
      values: {
        email,
        fullName: `${firstName} ${lastName}`,
        line1, line2, city, zipCode, state, country
      },
      errors: {
        ...errors,
        zipCode: validateZip(country, zipCode)
      }
    }));
    setIsDisabled(false);
    setHasPPInfo(true);
  };

  const handleSuccess = async (nonce) => {
    const IS_CREDIT = method === 'CARD';
    const WANTS_AR  = wantsAR || (!active && !IS_30_AFTER_EXP && autoRenew);
    let postData;
    let requestData = {
      autorenewAgreed: true,
      considerForAutorenew: WANTS_AR
    };

    if (DEFAULT_PAY_UPDATE) {
      requestData.defaultMethod = true;
    } else if (product) {
      requestData.productLineUriName = product;
    }

    try {
      const { data } = await NetworkCalls.callUpdatePaymentNonce(nonce, requestData);
      const paymentResp = await NetworkCalls.callGetPaymentMethods();
      // console.log(paymentResp.data);

      if (data?.autorenewInitiated) {
        setQcsRanAr(data.initiatedAutorenew);
      }

      if (IS_CREDIT) MixPanel.track(MixPanel.MIX_PANEL_IDS.CC_CHANGE);

      globalDispatch(Actions.setCtxNestedObj('payment', {credit: [data.paymentMethod]}));
      globalDispatch(Actions.setCtxNestedObj('payment', 
        UserSelectors.filterPaymentMethods(product, data.paymentMethod, state)
      ));
      globalDispatch(Actions.mergeMainStateObj({
        payMethods: paymentResp.data
      }));
    } catch (err) {
      console.log(err);
      if (IS_CREDIT) MixPanel.error(err, MixPanel.MIX_PANEL_IDS.CC_CHANGE);
      setHasError(true);
      return false;
    }

    if (!IS_CREDIT && !HAS_ADDRESS) {
      try {
        const ADDRESS_OBJ = {
          fullName: `${paypalForm?.values?.fullName?.trim()}`,
          ...paypalForm?.values
        };
        
        if (ADDRESS_OBJ?.state && typeof (ADDRESS_OBJ?.state) === 'string') {
          ADDRESS_OBJ.state = ADDRESS_OBJ.state.trim();
        }
        await callUpdateAddress(USER_ID, ADDRESS_OBJ);
        MixPanel.track(MixPanel.MIX_PANEL_IDS.ADDRESS);

        globalDispatch(Actions.setCtxNestedObj('personalInfo', ADDRESS_OBJ));
      } catch (err) {
        console.log(err);
        MixPanel.error(err, MixPanel.MIX_PANEL_IDS.ADDRESS);
        setHasError(true);
      }
    }


    if (IS_ACME && acmeAction?.action === 'CHANGE_PLAN') {
      const {data} = await NetworkCalls.callUpdateRenewalFrequency(product, 'P1Y');
      globalDispatch(Actions.mergeMainStateObj({
        subscriptions: ProductSelectors.mergeSubscription(state, product, data.postState),
        alert:         {
          type:     'SUCCESS',
          messages: [
            'Quicken Simplifi plan has successfully been updated',
            'Successfully updated billing info'
          ]
        }
      }));

      return onClose();
    }

    if (!paymentOnly && active && !autoRenew) {
      try {
        // tealiumLink(getTealiumObj(renewalSku, tierName, PRICE));
        gtmLink(getTealiumObj(renewalSku, tierName, PRICE));
        const {data: {postState}} = await NetworkCalls.callUpdateAutoRenew({
          userId: USER_ID, product, autoRenew: true
        });

        MixPanel.track(MixPanel.MIX_PANEL_IDS.AUTO_RENEW);
        globalDispatch(Actions.mergeSubscriptionData(product, postState));
        postData = postState;
      } catch (err) {
        console.log(err);
        if (IS_CREDIT) MixPanel.error(err, MixPanel.MIX_PANEL_IDS.AUTO_RENEW);
        setHasError(true);
      }
    }

    if (isDefaultPayment) {
      globalDispatch(Actions.setCtxNestedObj('alert', {
        type: 'SUCCESS',
        messages: [
          'Your default payment has been updated!',
        ]
      }));
      onClose();
    } else if (
      !IS_ACME && 
      !qcsRanAr &&
      (wantsAR || (!active && (IS_30_AFTER_EXP || (!IS_30_AFTER_EXP && !autoRenew))))
    ) {
      // tealiumLink(getTealiumObj(renewalSku, tierName, PRICE));
      gtmLink(getTealiumObj(renewalSku, tierName, PRICE));
      window.location = getEStoreRenewal(state.country);
    } else if (!paymentOnly && postData) {
      globalDispatch(Actions.setCtxNestedObj('alert', {
        type:     'SUCCESS',
        messages: [
          'Your membership is now set up!',
          `Your ${postData.productName} renewal date is ${TimeHelpers.getShortDate(postData.expireOn)}`
        ]
      }));
      onClose();
    } else {
      globalDispatch(Actions.setCtxNestedObj('alert', {
        type:     'SUCCESS',
        messages: [qcsRanAr ? 'Successfully updated billing info and autorenew has been initiated.' : 'Successfully updated billing info']
      }));
      onClose();
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (method === 'CARD') {
      const ERROR_FIELDS = Object.keys(_hostedFields._state.fields)
        .reduce((acm, cur) => {
          const {isEmpty, isValid, isPotentiallyValid} = _hostedFields._state.fields[cur];
          return {...acm, [cur]: (!isEmpty && (isValid || isPotentiallyValid)) ? '' : PaymentHelpers.getErrorMsgs(cur)};
        }, {});

      if (Object.values(ERROR_FIELDS).some(val => val !== '')) {
        return setCCErrors({form: '', ...ERROR_FIELDS});
      } else {
        setIsDisabled(true);

        try {
          const {nonce} = await _hostedFields.tokenize();
          await handleSuccess(nonce);
        } catch (err) {
          setHasError(true);
        }
      }
    } else {
      if (Object.values(paypalForm.errors).some(val => val !== '')) return;

      const ERROR_OBJ = {
        fullName: '',
        zipCode:  validateZip(paypalForm.values.country, paypalForm.values.zipCode)
      };

      if (Object.values(paypalForm.errors).some(val => val !== '')) {
        setPayPalForm(({values}) => ({values, errors: {form: '', ...ERROR_OBJ}}));
      } else {
        try {
          setIsDisabled(true);
          await handleSuccess(_nonce);
        } catch (err) {
          console.log(err);
          setHasError(true);
          setIsDisabled(false);
        }
      }
    }
  };

  const initBrainTree = React.useCallback((clientErr, client) => {
    if (clientErr) {
      console.error('Error creating client:', clientErr);
      setHasError(true);
      return;
    }

    paypalCheckout.create(
      {client},
      (clientErr, paypalCheckoutInstance) => {
        if (clientErr) {
          setHasError(true);
          return;
        }

        paypalCheckoutInstance.loadPayPalSDK(
          {vault: true, intent: 'tokenize'},
          ((tokenErr, paypalCheckoutInstance) => {
            if (tokenErr) {
              console.error('Error loadPayPalSDK:', tokenErr);
              setHasError(true);
              return;
            }

            return window.paypal.Buttons({
              locale:        'en_US',
              fundingSource: 'paypal',
              style:         {layout: 'horizontal', label: 'pay', size: 'responsive'},

              createBillingAgreement: () => paypalCheckoutInstance.createPayment({flow: 'vault'}),

              onError: (err) => {
                console.error('PayPal error', err);
                setHasError(true);
              },

              onApprove: data =>
                paypalCheckoutInstance.tokenizePayment(data)
                  .then(payload => handlePayPalInfo(payload))
                  .catch(err => {
                    console.log('PayPal Approval Error:', err);
                    setHasError(true);
                  })
            }).render('#paypal-button');
          }));
      });


    hostedFields.create({
      client,
      fields: {
        cardholderName: {selector: '#simp-cc-name', placeholder: 'Enter Card holder Name'},
        cvv:            {selector: '#simp-cvv', placeholder: 'XXX'},
        postalCode:     {selector: '#simp-cc-postal', placeholder: 'Enter Zip Code'},
        number:         {selector: '#simp-cc-number', placeholder: 'XXXX XXXX XXXX XXXX'},
        expirationDate: {selector: '#simp-cc-expires', placeholder: 'MM / YY'}
      }
    }, (err, hostedFields) => {
      if (err) setHasError(true);
      hostedFields.on('blur', handleBlur);
      hostedFields.on('validityChange', handleValidityChange);
      setHostedFields(hostedFields);
      setIsDisabled(false);
    });
  }, []);

  React.useEffect(() => {
    client.create(
      {authorization: process.env.REACT_APP_BRAINTREE_TOKEN},
      initBrainTree
    );
  }, [initBrainTree]);

  if (hasError) {
    return (
      <ModalWrapper heading="Set up payment method" onClose={onClose} hasLogos>
        <div className={S.modalBody}>
          <Message
            type="ERROR"
            align="CENTER"
            messages={['Server error occurred please try again later']}
          />
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper heading="Set up payment method" onClose={onClose} hasLogos>
      <div className={S.modalBody}>
        <form onSubmit={handleSubmit} className={S.paymentForm}>
          <h3>Add your credit card or PayPal details so that your subscription renewal processes smoothly.</h3>
          <PaymentSwitcher setMethod={setMethod} method={method} isCanada={IS_CANADA} isDefaultPayment={isDefaultPayment}/>

          <CCForm errors={ccErrors} isVisible={method === 'CARD'}/>

          <PayPalForm
            country={state.country}
            isDisabled={!_nonce || isDisabled}
            setForm={setPayPalForm}
            isVisible={method === 'PAYPAL'}
            {...paypalForm}
          />



          <div className={S.btnRow}>
            {SHOW_RENEW_NOW && (
              <CheckboxField
                label="Renew Now"
                id="userWantsAutoRenew"
                name="wantsAutoRenew"
                isChecked={wantsAR}
                onChange={() => setWantsAR(!wantsAR)}
                isDisabled={isDisabled}
                className={S.checkbox}
              />
            )}

            { (method != 'ACH') &&
              <ModalBtnRow
                form="UPDATE_PAYMENT_FORM"
                submitText={wantsAR ? 'Place Order' : 'Save Billing'}
                hasError={false}
                onCancel={onClose}
                isDisabled={method === ('CARD' || 'ACH') ? isDisabled : (!hasPPInfo || isDisabled)}
                className={S.pushRight}
              />
            }

          </div>
        </form>

        {!IS_CANADA && 
          <AchForm
            isVisible={method === 'ACH'}
            ctx={ACH_CTX}
            onClose={onClose}
          />
        }
      </div>
    </ModalWrapper>
  );
};

PaymentModal.displayName = 'PaymentModal';
PaymentModal.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    sku:         PropTypes.string,
    product:     PropTypes.string.isRequired,
    paymentOnly: PropTypes.bool.isRequired,
    isDefaultPayment: PropTypes.bool.isRequired,
    acmeAction:  PropTypes.shape({
      action: PropTypes.string
    })
  })
};

export { PaymentModal };
