import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'classnames';

import * as MixPanel from '../../../helpers/mixpanel';
import * as S from './cancel-downgrade.module.scss';
import * as NetworkCalls from '../../../network/network-calls';
import * as Actions from '../../../context/ctx-actions';
import * as ProductSelectors from '../../../context/selectors/product-selectors';

import { useGlobalCtx } from '../../../context/ctx-hook';
import { filterSimpSubs, createSubscriptionMap } from '../../../helpers/pure-functions';
import { getFormattedDate } from '../../../helpers/time-helpers';
import { ProductName } from '../../common/product/prod-name';

function getExpiryDate(SUB) {
  if(SUB?.active === true && SUB?.isInGracePeriod ) {
    return getFormattedDate(SUB?.expireOn);
  }
  if(SUB?.active === false) {
    return getFormattedDate(SUB?.inactiveAt);
  } else {
    return getFormattedDate(SUB?.expireOn);
  }
}

const CancelDowngradeBox = ({product, className, onClose}) => {
  const {globalDispatch, state} = useGlobalCtx();
  const SUB = ProductSelectors.getSubscription(state, product);
    
  const handleCancelDowngrade = async () => {
    try {
      globalDispatch(Actions.mergeMainStateObj({modal: {isDisabled: true }}));
      
      const { data } = await NetworkCalls.callPostCancelDowngrade(product);
      const subResponseAgain = await NetworkCalls.callGetSubscriptions();
      const SUB_MAP = filterSimpSubs(createSubscriptionMap(subResponseAgain.data, state.country));

      globalDispatch(Actions.mergeMainStateObj({
        isDisabled: false,
        subscriptions: SUB_MAP,
        // alert: { type: 'SUCCESS', messages: ['Your membership is now set up']},
      }));
      // onClose();
    } catch (err) {
      console.log(err);
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.DOWNGRADE);
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  };

  return (
    <div className={ClassNames(S.cancelDowngrade, className)} data-testid={'CANCEL_DWNGRD_BOX'}>

      <div className={S.topText}>
        <h3 className={S.title}> Downgraded subscription</h3>

        <div className={S.downgradeMsg}>
          <span>Your subscription will downgrade to </span>
          <ProductName className={S.cancelDwngradeName} sub={SUB} nameStyle={'textOnly'} customName={SUB?.renewalTierName} />
          <span> on </span>
          <span> {getExpiryDate(SUB)} </span>
        </div>
      </div>


      <div className={S.btnRow}>
        <button
          type="button"
          onClick={handleCancelDowngrade}
          className={[`${S.contentCTABtn} ${S.cancelBtn}`]}
          disabled={false}
        >Keep {SUB.tierName} </button>
      </div>
    </div>
  );
};

CancelDowngradeBox.propTypes = {
  onClose: PropTypes.func,
  product: PropTypes.string.isRequired,
  className: PropTypes.string
};

export { CancelDowngradeBox };