import React from 'react';
import PropTypes   from 'prop-types';

// import { BM_TILE, QOB_TILE, RenderedSubtiles } from '../../../../helpers/tile-helpers';

import * as S                 from './tile-qkn.module.scss';
import { Legal }              from '../../../common/legal';
import { ProductName }        from '../../../common/product/prod-name';
import { TagLine }            from '../../../common/product/tag-line';
import { Expiry }             from '../../../common/product/expiry';
import { Price }              from '../../../common/product/price';
import { CancelledBox }       from '../../../common/product/cancelled-box';
import { RemainingTime }      from '../../../common/product/remaining-time';
import { setCtxNestedObj }    from '../../../../context/ctx-actions';
import { useGlobalCtx }       from '../../../../context/ctx-hook';
import { getBillManagerType } from '../../../../context/selectors/bill-selectors';
import * as ProductSelectors  from '../../../../context/selectors/product-selectors';
import * as UserSelectors     from '../../../../context/selectors/user-selectors';
import * as StateConstants    from '../../../../helpers/constants/state-constants';
import { useAutoRenew }       from '../../../../helpers/hooks/use-auto-renew';
import * as RouteHelpers      from '../../../../helpers/route-helper';
import { isInBetween }        from '../../../../helpers/time-helpers';
import { getQknPriceWarn }    from '../../../../helpers/skunotices-helpers';
// import { QobPaid }            from '../tile-qob/qob-paid';
// import { BmPaid }             from '../tile-bm/bm-paid';
// import { BmFree }             from '../tile-bm/bm-free';
import { AddonTile }          from '../tile-addon-min/tile-addon-min';
import { CancelDowngradeBox } from '../../../modals/change-plans/cancel-downgrade';

const getAccountMsg = ({ active, autoRenew }, hasPayment) => {
  if (!active) {
    return {
      title: 'Expired Subscription',
      message: 'This subscription is no longer active',
    };
  } else if (!hasPayment) {
    return {
      title: 'No Payment Method',
      message: 'In order to not lose your connected services, add a payment method for renewal'
    };
  } else if (!autoRenew) {
    return {
      title: 'Renewal Canceled',
      message: 'Your subscription has been canceled but it is still valid until your expiration date. In order to avoid expiration, you must resume your subscription.'
    };
  } else {
    return false;
  }
};

const getSubSumPrice = (subList, state) => {
  const subPriceList = subList.map(sub => { 
    if(sub) {
      return ProductSelectors.getPriceBySku(state, sub.renewalSku);
    }
  });

  const totalSum = subPriceList.reduce((accumulator, price) => {
    const numericValue = parseFloat(price.replace('$', ''));
    return Math.round((accumulator + numericValue) * 100) / 100;
  }, 0);
  return totalSum;
};


const TileQkn = ({isModal = false}) => {
  const {globalDispatch, state} = useGlobalCtx();

  const PRODUCT      = `quicken-${state.country}`;
  const BM_TYPE      = getBillManagerType(state);
  const HAS_QOB      = !!state.subscriptions?.['quicken-online-backup'];
  const SUB          = ProductSelectors.getSubscription(state, PRODUCT);
  const PENDING_DOWNGRADE = SUB?.renewalTierUriName ? SUB?.renewalTierUriName !== SUB?.tierUriName : false;
  const HAS_PAYMENT  = UserSelectors.getProductsPaymentMethod(PRODUCT, state);
  const HISTORY_LIST = UserSelectors.getUserSubHistory(state, PRODUCT);
  const MESSAGE      = getAccountMsg(SUB, HAS_PAYMENT);
  const AR_ON_SUBS   = [];
  if(SUB?.autoRenew) {
    AR_ON_SUBS.push(SUB);
  }
  if(HAS_QOB) {
    AR_ON_SUBS.push(ProductSelectors.getSubscription(state, 'quicken-online-backup'));
  }
  const TOTAL_SUB_PRICE = '$' + getSubSumPrice(AR_ON_SUBS, state);

  let {active, autoRenew, tierUriName, renewalFrequency, expireOn, effectiveAt} = SUB;
  // console.log(SUB);
  // console.log(active);
  const TIER_NOTICES = getQknPriceWarn(tierUriName, expireOn, state.country);
  let PRICE = '';

  if(state.country === 'us') {
    PRICE = (TIER_NOTICES?.HAS_PRICE_UPDATE)
      ? TIER_NOTICES.PRICE 
      : ProductSelectors.getQknPrice(state, tierUriName);
  }

  if(state.country === 'ca') {
    PRICE = (TIER_NOTICES?.HAS_PRICE_UPDATE)
      ? TIER_NOTICES.PRICE 
      : ProductSelectors.getQknPrice(state, tierUriName);
  }

  const handleAROn = useAutoRenew(PRODUCT, true);

  const IS_CANCELLED = React.useMemo(
    () =>
      !SUB.active || (!autoRenew && HISTORY_LIST?.filter(({action, createdAt}) =>
        (action === 'DISABLE_AUTORENEW' && isInBetween(SUB.effectiveAt, expireOn, createdAt))
      ).length > 0),
    [autoRenew, effectiveAt, expireOn, HISTORY_LIST]
  );

  const manageSubscription = React.useCallback(
    async () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.SUBSCRIPTION,
      ctx:        {product: [SUB]},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch, PRODUCT]
  );

  const handleUpdateBilling = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.UPDATE_PAYMENT,
      ctx:       { product: PRODUCT, paymentOnly: true},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch, PRODUCT]
  );


  const updatePaymentModal = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.UPDATE_PAYMENT,
      ctx:        { product: PRODUCT, paymentOnly: false, isDefaultPayment: true},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch, PRODUCT]
  );

  // const renderSubtiles = () => {
  //   // Renders included sub tile objects:
  //   // component: component to render (imported from tile-helpers.js for additional logic)
  //   // purchase_date: date used to order rendering (most recent purchase closest to top)
  //   // display_dependency_flagged: Prevents the rendering of the tile unless resolved to false

  //   const tilesToRender = [ 
  //     {
  //       product : 'quicken-online-backup',
  //       component : QOB_TILE(HAS_QOB, isModal, active, autoRenew),
  //       purchase_date: ProductSelectors.getEffectiveMoment(state, 'quicken-online-backup'),
  //       display_dependency_flagged: false
  //     },
  //     {
  //       product : 'billmanager',
  //       component: BM_TILE(BM_TYPE, isModal, active),
  //       purchase_date: ProductSelectors.getEffectiveMoment(state, 'billmanager'),
  //       display_dependency_flagged: false
  //     },
  //   ];
  //   return RenderedSubtiles(tilesToRender);
  // };

  const HAS_ADDONS = HAS_QOB || BM_TYPE != 'NONE';
  // console.log(HAS_QOB);
  // console.log(BM_TYPE);
  // active = false;
  // console.log(active);

  return (
    <div className={isModal ? S.qknModalWrapper : S.qknWrapper} data-testid="SUB_QKN">
      <div className={`${S.tile} ${SUB.tierUriName}`}>

        <div className={S.subTileTop}>
          <div className={S.subTileLeft}>
            {/* {IS_CANCELLED && <CancelledBox className={S.cancelBox} />} */}
            <ProductName sub={SUB} className={S.qknNameArea} product='quicken' />
            <TagLine uriName={tierUriName} className={S.tagLine} />

            {!MESSAGE && 
              <Price price={TOTAL_SUB_PRICE} product={PRODUCT} renewalFrequency={renewalFrequency} className={`${S.price} ${HAS_ADDONS ? S.hasAddons : false}`} />
            }
          </div>
          <div className={S.subTileRight}>

            {active && !autoRenew || !HAS_PAYMENT && (
              <RemainingTime expireOn={expireOn} className={S.remaining} />
            )}

            <Expiry product={PRODUCT} className={S.productExpiry} />

            {/* Messaging is only for CA, did not do this for US price change */}
            {/* {(TIER_NOTICES?.HAS_PRICE_UPDATE && TIER_NOTICES?.WARN_WAVE === 'WAVE1' && state.country === 'ca') &&
              <p className={[`${S.qknTagLine} ${S.caPriceWarn}`]}>
                *The annual price for Quicken {tierName} will increase to {TIER_NOTICES.NEW_PRICE} on October 18 2022. You will be charged the new price at your next renewal.
              </p>
            } */}

            <div className={`${S.actionBtnWrapper} ${HAS_ADDONS ? S.hasAddons : false}`} >
              {!isModal && active && autoRenew && HAS_PAYMENT && (
                <button
                  type="button"
                  data-testid="BTN_QKN_MANAGE_SUB"
                  onClick={manageSubscription}
                  className={S.ctaBtn}
                >Manage Subscription</button>
              )}

              {active && autoRenew && !HAS_PAYMENT && (
                <button
                  type="button"
                  data-testid="BTN_QKN_BILLING"
                  onClick={handleUpdateBilling}
                  className={S.ctaBtn}
                >Add Billing</button>
              )}

              {active && !autoRenew && (
                <>
                  <button
                    type="button"
                    data-testid="QKN_RESTART_SUB"
                    onClick={HAS_PAYMENT ? handleAROn : updatePaymentModal}
                    className={S.ctaBtn}
                  >Resume Subscription</button>
                  {/* <Legal className={S.legal} /> */}
                </>
              )}

              {!active && (
                <a
                  data-testid="LINK_RENEW_QKN"
                  className={S.ctaBtn}
                  href={RouteHelpers.getEStoreRenewal(state.country)}
                >Buy Now</a>
              )}
            </div>
          </div>
        </div>


        {PENDING_DOWNGRADE && SUB.autoRenew &&
          <CancelDowngradeBox product={PRODUCT} className={'mainTile'} />
        }

        {!isModal && MESSAGE && 
          <div className={S.productAlert}>
            <p className={S.messageTitle}>{MESSAGE.title}</p>
            <p>{MESSAGE.message}</p>
          </div>
        }


        {HAS_ADDONS &&
          <div className={S.subTileBtm}>
            <span className={S.dash} />
            <p className={S.addOnTitle}>Add-ons</p>

            <div className={S.addonContain} data-testid={'ADDONS_CONTAINER'}>
              {BM_TYPE == 'FREE' &&
                <AddonTile
                  isModal={isModal}
                  qknIsActive={autoRenew}
                  product={'billmanager'}
                  sub={SUB}
                  allSubsExpired={!active}
                  allSubsArOff={!autoRenew}
                />
              }

              {BM_TYPE == 'PAID' &&
                <AddonTile
                  isModal={isModal}
                  qknIsActive={autoRenew}
                  sub={SUB}
                  product={'billmanager'}
                  allSubsExpired={!active}
                  allSubsArOff={!autoRenew}
                />
              }

              {HAS_QOB &&
                <AddonTile
                  isModal={isModal}
                  qknIsActive={autoRenew}
                  product={'quicken-online-backup'}
                  sub={SUB}
                  allSubsExpired={!active}
                  allSubsArOff={!autoRenew}
                />
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

TileQkn.displayName  = 'TileQkn';
TileQkn.propTypes    = {isModal: PropTypes.bool};
TileQkn.defaultProps = {isModal: false};

export { TileQkn };
