export const getUserSubHistory = (globalState, product) => {
  if(product === 'all' ) {
    return Object.values(globalState?.history).flat();
  } else {
    return globalState?.history?.[product] ?? [];
  }
};

export const getUserSubHistoryV2 = (globalState, product) => {
  if (product === 'all') {
    return Object.values(globalState?.historyV2).flat();
  } else {
    return globalState?.historyV2?.[product] ?? [];
  }
};

export const getUserId = globalState => globalState.userId;

// returns payment object
// export const getUserCredit       = globalState => globalState.payment.credit;
// export const getUserAch          = globalState => globalState.payment.ach;
export const getUserAchDeclined  = (product, globalState) => {
  const ACH_METHOD = getProductsPaymentMethod(product, globalState);
  const IS_ACH = ACH_METHOD ? ACH_METHOD.paymentMethodType === 'ACH' : false;
  return IS_ACH ? ACH_METHOD?.isHardDeclined : false;
};
export const getUserBtErr        = globalState => globalState.payment.hasBraintreeError;
export const getUserBtNetErr     = globalState => globalState.payment.hasBraintreeNetError;
export const getUserBtErrMsg     = globalState => globalState.payment.btErrMsg;


//returns boolean
export const getUserHasCredit     = globalState => globalState?.payment?.credit.length > 0;
export const getUserHasAch        = globalState => globalState?.payment?.ach.length > 0;
export const getUserAchIsDeclined = (product, globalState) => !!getUserAchDeclined(product, globalState);
export const getUserHasBtError    = globalState => !!getUserBtErr(globalState);
export const getUserHasBtNetErr   = globalState => !!getUserBtNetErr(globalState);
export const getUserHasBtErrorMsg = globalState => getUserBtErrMsg(globalState);
export const getUserHasPayment    = (globalState, product) =>
  product === 'billmanager'
    ? getUserHasAch(globalState)
    : getUserHasCredit(globalState);

// Survey modal state variable
export const getSurveySrc      = globalState => globalState.modal.surveySrc;
export const getProductCtx     = globalState => globalState.modal.ctx;


export const getProductsPaymentMethod = (product, globalState) => {
  let paymentMethod = null;
  let defaultPayment = null;

  globalState?.payment?.ach.forEach(curMethod => {    
    if (curMethod?.defaultForProductLines?.includes(product)) {
      paymentMethod = curMethod;
    }
    if (curMethod?.defaultMethod === true) {
      defaultPayment = curMethod;
    }
  });

  globalState?.payment?.credit.forEach(curMethod => {
    if (curMethod?.defaultForProductLines?.includes(product)) {
      paymentMethod = curMethod;
    }

    if (curMethod?.defaultMethod === true) {
      defaultPayment = curMethod;
    }
  });
  
  return paymentMethod ? paymentMethod : defaultPayment;
};

export const filterPaymentMethods = (product, PAYMENT_METHOD, globalState) => {
  // when payments switch we need to not only add payment methods to 
  // their new object key but pop off the old one
  let payObj = {
    ach: [],
    credit: []
  };

  if (PAYMENT_METHOD?.paymentMethodType === 'ACH') {
    payObj.ach.push(PAYMENT_METHOD);
  } else {
    payObj.credit.push(PAYMENT_METHOD);
  }

  globalState?.payment?.ach?.forEach(curMethod => {
    if (!curMethod?.defaultForProductLines?.includes(product)) {
      payObj.ach.push(curMethod);
    }
    if (curMethod?.defaultMethod === true) {
      if (curMethod?.defaultForProductLines?.includes(product)) {
        const index = curMethod?.defaultForProductLines?.indexOf(product);
        if (index !== -1) {
          curMethod?.defaultForProductLines?.splice(index, 1);
        }
      }
      payObj.ach.push(curMethod);
    }
  });

  globalState?.payment?.credit?.forEach(curMethod => {
    if (!curMethod?.defaultForProductLines?.includes(product)) {
      payObj.credit.push(curMethod);
    }
    if (curMethod?.defaultMethod === true) {
      if (curMethod?.defaultForProductLines?.includes(product)) {
        const index = curMethod?.defaultForProductLines?.indexOf(product);
        if (index !== -1) {
          curMethod?.defaultForProductLines?.splice(index, 1);
        }
      }
      payObj.credit.push(curMethod);
    }
  });

  return payObj;
};
